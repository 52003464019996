import {
  SPACER_CE_NAME,
  SpacerContentElementProps,
} from "./spacerContentElement";

/**
 * This is the global style of a CE.
 * This style is applied on all instances of this CE
 *
 * @param props
 * @returns
 */
export default function SpacerContentElementStyleGlobal(
  props: SpacerContentElementProps
) {
  return (
    <style jsx global>
      {`
        :global(.${SPACER_CE_NAME}-wrapper) {
          // Spacer always has the configured spacer height and ignores
          // verticalpaddingafterfirstelement/verticalpaddingbeforefooter and verticalpadding
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }
        :global(.${SPACER_CE_NAME}) {
          .spacer {
            margin: -1px 0;
          }
        }
      `}
    </style>
  );
}
