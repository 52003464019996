import useScopedClassName from "@/hooks/useScopedClassName";
import { ContentElementSpacerStoreSetting } from "@/types/ceSettings/ceSettings";
import { CESpacer } from "@/types/content-elements";
import clsx from "clsx";
import React from "react";
import { Col, Row } from "react-bootstrap";
import ContentElementWrapper from "../wrapper/contentElementWrapper";
import SpacerContentElementStyleGlobal from "./spacerContentElementStyleGlobal";
import SpacerContentElementStyleScoped from "./spacerContentElementStyleScoped";

export interface SpacerContentElementProps {
  content: CESpacer;
  position: number;
  ceSettings?: ContentElementSpacerStoreSetting;
  isLastElement: boolean;
  isMobile: boolean;
  isFirstElement: boolean;
}

export const SPACER_CE_NAME = "spacerCE";

export default React.memo(function SpacerContentElement(
  props: SpacerContentElementProps
) {
  const SPACER_SCOPED_CLASS = useScopedClassName(SPACER_CE_NAME, props.content, props.position);

  return (
    <>
      <ContentElementWrapper
        name={SPACER_CE_NAME}
        scopedClassName={SPACER_SCOPED_CLASS}
        content={props.content}
        ceSettings={props.ceSettings}
        isFirstElement={props.isFirstElement}
        isLastElement={props.isLastElement}
        position={props.position}
      >
        <Row className={clsx(SPACER_CE_NAME, "g-0", SPACER_SCOPED_CLASS)}>
          <Col>
            <div className="spacer" />
          </Col>
        </Row>
      </ContentElementWrapper>
      <SpacerContentElementStyleGlobal {...props} />
      <SpacerContentElementStyleScoped
        {...props}
        scopedSelector={SPACER_SCOPED_CLASS}
      />
    </>
  );
});
