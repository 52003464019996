import {
  lowerBreakpointDesktopPx,
  lowerBreakpointMobilePx,
  lowerBreakpointTabletPx,
} from "@/utils/util";
import { SpacerContentElementProps } from "./spacerContentElement";

interface SpacerContentElementStyleScopedProps
  extends SpacerContentElementProps {
  scopedSelector: string;
}

/**
 * This is the scoped style from a single CE instance
 * Here you can explicitly style one CE
 *
 * @param props
 * @returns
 */
export default function SpacerContentElementStyleScoped(
  props: SpacerContentElementStyleScopedProps
) {
  return (
    <style jsx global>{`
      :global(.${props.scopedSelector}) {
        @media (min-width: ${lowerBreakpointMobilePx()}) {
          :global(.spacer) {
            height: ${props.ceSettings?.height?.mobile
              ? props.ceSettings.height.mobile *
                (props.content.cfgIsDoubledHeight ? 2 : 1)
              : 0}px;
          }
        }
        @media (min-width: ${lowerBreakpointTabletPx()}px) {
          :global(.spacer) {
            height: ${props.ceSettings?.height?.tablet
              ? props.ceSettings.height.tablet *
                (props.content.cfgIsDoubledHeight ? 2 : 1)
              : 0}px;
          }
        }
        @media (min-width: ${lowerBreakpointDesktopPx()}) {
          :global(.spacer) {
            height: ${props.ceSettings?.height?.desktop
              ? props.ceSettings.height.desktop *
                (props.content.cfgIsDoubledHeight ? 2 : 1)
              : 0}px;
          }
        }
      }
    `}</style>
  );
}
